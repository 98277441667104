<template>
  <div class="layoutDefault" style="margin-top: 2rem">
    <div class="grid grid-cols-1 gap-4">
      <div class="z-10">
        <div
          class="flex cursor-pointer rounded-lg py-2"
          @click="$router.go(-1)"
          style="width: 350px"
        >
          <IconArrow
            class="mr-3 mt-1 stroke-current text-habu h-6 w-6 stroke-2 transform rotate-0"
          />
          <span class="text-2xl text-normal">Detail Broadcast Message</span>
        </div>
      </div>

      <div class="hcard relative z-10 p-7">
        <div class="grid-cols-1 mb-7">
          <div class="font-semibold text-black text-lg">{{ broadcast.title }}</div>

          <div class="my-3" style="border-bottom: 1px solid #bfbfbf"></div>
          <div class="text-sm font-medium mb-5">
            {{ broadcast.published_at }}
          </div>
          <!-- <div class="text-black text-sm">{{ broadcast.message }}</div> -->
          <div
            class="text-black text-sm whitespace-pre-line tracking-wide leading-relaxed"
            v-html="broadcast.message"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconArrow from '@/assets/icons/icon-arrow.svg?inline'

import { mapState } from 'vuex'

export default {
  name: 'broadcasts',
  components: {
    IconArrow
  },
  computed: {
    ...mapState({
      broadcast: state => state.notif.broadcast.detail
    })
  }
}
</script>
